import React, { useEffect, useState } from 'react'
import {
	ChipInputField,
	ChipInputList,
	StyledHeader,
	StyledNameField,
} from '../components/reusableComponents'
import { BrowserUpdatedOutlined, InfoOutlined } from '@mui/icons-material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Checkbox, useTheme } from '@mui/material'
import { domToPng } from 'modern-screenshot'
import { adTemplates } from './ad_templates_settings'
import FileUploader from './FileUploader'
import AdTemplate from './AdTemplate'
import { InputFieldText } from '../components/Inputs'
import { DropList } from '../components/SelectableInputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { StyledInputLabel } from '../campaigns/CampaignStyled'

type TrafficLabelProps = {
	label: string
}

const TrafficLabel: React.FC<TrafficLabelProps> = ({ label }) => (
	<span
		style={{
			fontWeight: '600',
			color: 'rgba(128, 128, 128, 0.8)',
			padding: '0.2rem 2.5rem',
		}}
	>
		{label} Traffic :
	</span>
)

interface Props {
	setErrorMessage?: any
	setUserMessage?: any
	setIsUserMessagePromptOpened?: any
	setLoading: any
}

export const AdEditor: React.FC<Props> = (props) => {
	const theme = useTheme()
	const [headline, setHeadline] = useState<string | null>('')
	const [text, setText] = useState<string | null>('')
	const [cta, setCta] = useState<string | null>('')
	const [hashtags, setHashtags] = useState<string[]>([
		'hashtag1',
		'hashtag2',
		'hashtag3',
	])

	const [adTemplatesList, setAdTemplatesList] = useState<any>([...adTemplates])
	const [selectedTemplates, setSelectedTemplates] = useState<any>([])
	const [selectedVertical, setSelectedVertical] = useState<string>('Sports')
	const [hoveredVertical, setHoveredVertical] = useState<string>('')
	const [isAtBottom, setIsAtBottom] = useState(false)

	const [files, setFiles] = useState<any>([])
	const [isPreview, setIsPreview] = useState<boolean>(false)

	useEffect(() => {
		props.setLoading(false)

		return () => {}
	}, [])

	useEffect(() => {
		const handleScroll: any = () => {
			const scrollTop = window.scrollY
			const windowHeight = window.innerHeight
			const bodyHeight = document.body.scrollHeight - windowHeight

			if (scrollTop >= bodyHeight) {
				setIsAtBottom(true)
			} else {
				setIsAtBottom(false)
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const handleMouseEnter = (vertical: string) => {
		setHoveredVertical(vertical)
	}

	const handleMouseLeave = () => {
		setHoveredVertical('')
	}

	const checkTemplatePreview = (cat: string, type: string) => {
		const selectedTemplate = adTemplatesList.find(
			(template: any) =>
				(template.vertical === selectedVertical ||
					template.vertical === 'All') &&
				template.category === cat &&
				template.type === type,
		)
		if (!selectedTemplate) {
			return false
		}

		if (selectedTemplate.type === 'Video' && !selectedTemplate.videoPreview) {
			return true
		}
		if (
			cat === 'DSP & SDK' &&
			selectedTemplate.type === 'Banner' &&
			!selectedTemplate.smallBannerPreview
		) {
			return true
		}
		if (
			selectedTemplate.type === 'Interstitial' &&
			!selectedTemplate.interstitialPreview
		) {
			return true
		}
		if (
			cat === 'OEM' &&
			selectedTemplate.type === 'Banner' &&
			!selectedTemplate.bigBannerPreview
		) {
			return true
		}
		if (
			(cat === 'OEM' || cat === 'Social') &&
			(type === 'Home Page' ||
				type === 'Search Page' ||
				type === 'Influencer' ||
				type === 'Sponsored') &&
			!selectedTemplate.iconPreview
		) {
			return true
		}
		return false
	}

	const checkSelectAll = () => {
		for (let i = 0; i < adTemplatesList.length; i++) {
			let cat = adTemplatesList[i].category
			let type = adTemplatesList[i].type
			if (checkTemplatePreview(cat, type)) {
				return true
			}
		}
		return false
	}

	const createTemplatesDict = () => {
		const formattedTemplates: any = {}

		adTemplates.forEach((template) => {
			const { category, type } = template

			if (!formattedTemplates[category]) {
				formattedTemplates[category] = new Set()
			}

			formattedTemplates[category].add(type)
		})

		for (const category in formattedTemplates) {
			formattedTemplates[category] = Array.from(formattedTemplates[category])
		}

		return formattedTemplates
	}

	const createVerticalList = () => {
		const verticalList: string[] = []

		adTemplates.forEach((template) => {
			const verticalToAdd = template.vertical
			if (!verticalList.includes(verticalToAdd)) {
				verticalList.push(verticalToAdd)
			}
		})

		return verticalList
	}

	const toggleSelectAllHandler = () => {
		if (selectedTemplates.length === 9) {
			setSelectedTemplates([])
		} else {
			let filteredTemplates = [...adTemplatesList]
			filteredTemplates = filteredTemplates.filter(
				(template: any) =>
					template.vertical === selectedVertical || template.vertical === 'All',
			)
			setSelectedTemplates(filteredTemplates)
		}
	}

	const toggleSelectedTemplateHandler = (cat: string, type: string) => {
		const selectedTemplate = adTemplatesList.find(
			(template: any) =>
				(template.vertical === selectedVertical ||
					template.vertical === 'All') &&
				template.category === cat &&
				template.type === type,
		)
		const foundTemplate = selectedTemplates.find(
			(template: any) =>
				(template.vertical === selectedVertical ||
					template.vertical === 'All') &&
				template.category === cat &&
				template.type === type,
		)
		let updatedTemplates = [...selectedTemplates]
		if (foundTemplate) {
			updatedTemplates = updatedTemplates.filter(
				(size: any) => size.id !== selectedTemplate.id,
			)
		} else if (selectedTemplate) {
			updatedTemplates.push(selectedTemplate)
		}
		setSelectedTemplates(updatedTemplates)
	}

	const isSelected = (cat: string, type: string) => {
		const selectedTemplate = selectedTemplates.find(
			(template: any) =>
				(template.vertical === selectedVertical ||
					template.vertical === 'All') &&
				template.category === cat &&
				template.type === type,
		)

		if (selectedTemplate) {
			return true
		}
		return false
	}

	const downloadTemplateHandler = async (templateId: string) => {
		const capture = document.getElementById(templateId)

		if (!capture) {
			props.setErrorMessage('Something went wrong. Please try again later')
			return
		}

		await domToPng(capture, {
			scale: 1,
			debug: true,
			fetch: {
				requestInit: {
					mode: 'cors',
					headers: {
						'Cache-Control': 'no-cache, no-store, must-revalidate',
						Pragma: 'no-cache',
						Expires: '0',
					},
				},
				bypassingCache: true,
			},
		}).then((dataUrl) => {
			const link = document.createElement('a')
			const date = new Date()
			link.download = `ad_placement_${date.toLocaleString()}.png`
			link.href = dataUrl
			link.click()
			link.remove()
		})
	}

	const downloadVideoTemplateHandler = async (
		templateId: string,
		isHorizontal: boolean,
	) => {
		const capture = document.getElementById(templateId)

		if (!capture) {
			props.setErrorMessage('Something went wrong. Please try again later')
			return
		}
		// let captureCopy = capture.cloneNode(true) as HTMLElement
		// document.body.appendChild(captureCopy)
		// if (isHorizontal) {
		// 	captureCopy.style.transform = 'rotate(90deg)'
		// }

		const playIconDiv = document.getElementById('play')
		if (playIconDiv) {
			playIconDiv.style.zIndex = '10'
		}

		await domToPng(capture, {
			scale: 1,
			debug: true,
			fetch: {
				requestInit: {
					mode: 'cors',
					headers: {
						'Cache-Control': 'no-cache, no-store, must-revalidate',
						Pragma: 'no-cache',
						Expires: '0',
					},
				},
				bypassingCache: true,
			},
		}).then((dataUrl) => {
			const link = document.createElement('a')
			const date = new Date()
			link.download = `ad_placement_${date.toLocaleString()}.png`
			link.href = dataUrl
			link.click()
			link.remove()
		})
		// document.body.removeChild(captureCopy)

		if (playIconDiv) {
			playIconDiv.style.zIndex = '0'
		}
	}

	const setSelectedVerticalHandler = (
		oldVertical: string,
		newVertical: string,
	) => {
		setSelectedVertical(newVertical)
		let updatedTemplates = [...selectedTemplates]

		for (let i = 0; i < updatedTemplates.length; i++) {
			let index = i
			if (updatedTemplates[i].vertical === oldVertical) {
				const match = adTemplatesList.find(
					(template: any) =>
						template.category === updatedTemplates[i].category &&
						template.type === updatedTemplates[i].type &&
						template.vertical === newVertical,
				)
				if (match) {
					updatedTemplates.splice(index, 1, match)
				}
			}
		}

		setSelectedTemplates(updatedTemplates)
	}

	const checkCategoryInSelectedTemplates = (cat: string) => {
		return selectedTemplates.some((template: any) => template.category === cat)
	}

	const removeFileFromTemplatesHandler = (url: string, files: any) => {
		let templates = [...selectedTemplates]
		let templateList = [...adTemplatesList]

		const updateTemplatePreviews = (template: any) => {
			const previewTypes = [
				'smallBannerPreview',
				'bigBannerPreview',
				'iconPreview',
				'videoPreview',
				'interstitialPreview',
			]
			let isModified = false
			previewTypes.forEach((previewType) => {
				if (template[previewType] === url) {
					template[previewType] = ''
					isModified = true
				}
			})
			return { template, isModified }
		}

		templates = templates
			.map((template) => {
				const updated = updateTemplatePreviews(template)
				return updated.template
			})
			.filter((template) => {
				return Object.values(template).some((value) => value === url)
			})

		templateList = templateList.map(
			(template) => updateTemplatePreviews(template).template,
		)

		setSelectedTemplates(templates)
		setAdTemplatesList(templateList)
		setFiles(files)
	}

	const updateTemplatesWithFile = (template: any, file: any) => {
		if (file.type === 'video/mp4' && !template.videoPreview) {
			return { ...template, videoPreview: file.url }
		} else if (
			file.width > 0 &&
			file.width === file.height &&
			!template.iconPreview
		) {
			return { ...template, iconPreview: file.url }
		} else if (
			file.width === 300 &&
			file.height === 50 &&
			!template.smallBannerPreview
		) {
			return { ...template, smallBannerPreview: file.url }
		} else if (
			file.width === 300 &&
			file.height === 250 &&
			!template.bigBannerPreview
		) {
			return { ...template, bigBannerPreview: file.url }
		} else if (
			file.width === 320 &&
			file.height === 480 &&
			!template.interstitialPreview
		) {
			return { ...template, interstitialPreview: file.url }
		}
		return template
	}

	const setFilesHandler = (fileList: any[]) => {
		let updatedSelectedTemplates = [...selectedTemplates]
		let updatedAdTemplatesList = [...adTemplatesList]

		fileList.forEach((file) => {
			updatedSelectedTemplates = updatedSelectedTemplates.map((template) =>
				updateTemplatesWithFile(template, file),
			)
			updatedAdTemplatesList = updatedAdTemplatesList.map((template) =>
				updateTemplatesWithFile(template, file),
			)
		})

		setSelectedTemplates(updatedSelectedTemplates)
		setAdTemplatesList(updatedAdTemplatesList)

		setFiles((prevFiles: any) => [...prevFiles, ...fileList])
	}

	const setHashtagsHandler = (hashtags: string[]) => {
		hashtags = hashtags.slice(-3)
		setHashtags(hashtags)
	}

	const showRequirementsHandler = () => {
		const title = 'Ads Requirements'
		const text = `Files uploaded must fulfill the following requirements:\n\n- 300x50 image\n- 300x250 image\n- 320x480 image\n- an icon image (square)\n- an MP4 video`

		const userMsg = { title, text }
		props.setUserMessage(userMsg)
		props.setIsUserMessagePromptOpened(true)
	}

	const previewHandler = () => {
		setIsPreview(!isPreview)
	}

	return (
		<div
			style={{
				width: '95%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			}}
		>
			<div
				style={{
					width: '100%',
					// borderRadius: '10px',
					// boxShadow: '0px 1px 1px 0.5px ' + theme.colors.base.grey300,
					display: isPreview ? 'none' : 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						padding: '30px 45px 60px 80px',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyItems: 'center',
							gap: '80px',
						}}
					>
						<div>
							<div>
								<span
									style={{
										color: theme.colors.text.titles,
										fontWeight: 500,
										display: 'flex',
										alignItems: 'center',
										gap: '5px',
									}}
								>
									Upload Files{' '}
									<span
										onClick={showRequirementsHandler}
										title='Requirements'
										style={{
											color: '#686868',
											cursor: 'pointer',
										}}
									>
										<InfoOutlined />
									</span>
								</span>
							</div>
							<div style={{ marginTop: '2rem' }}>
								<FileUploader
									onErrorMessage={props.setErrorMessage}
									onSetFiles={setFilesHandler}
									onRemoveFile={removeFileFromTemplatesHandler}
								/>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								minWidth: '442px',
								// justifyContent: 'space-evenly',
							}}
						>
							<div style={{ paddingBottom: '1rem' }}>
								<span
									style={{
										color: theme.colors.text.titles,
										fontWeight: 500,
									}}
								>
									Insert Text
								</span>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '12px',
								}}
							>
								<InputFieldText
									onChange={(e: string) => setHeadline(e)}
									type='text'
									label='Headline'
									value={headline}
									style={{ width: '441px', height: '38px' }}
									inputProps={{ maxLength: 20 }}
								/>
								<InputFieldText
									onChange={(e: string) => setText(e)}
									type='text'
									label='Text'
									value={text}
									style={{
										width: '441px',
										height: '100px',
										paddingTop: '8px',
										overflow: 'auto',
									}}
									multiline={true}
									// inputProps={{ maxLength: 50 }}
								/>

								<ChipInputField
									label='HashTags'
									value={hashtags}
									onChange={setHashtagsHandler}
									options={[]}
									style={{
										width: '441px',
										background: theme.colors.base.white,
									}}
									chipStyle={{
										fontSize: '0.875rem',
										// backgroundColor: '#fff',
										border: '1px solid #ccc',
										cursor: 'pointer',
										hover: {
											// backgroundColor: '#f0f0f0',
										},
									}}
								/>

								<DropList
									onChange={(e: string) => setCta(e)}
									label='CTA'
									value={cta}
									style={{ width: '441px', height: '38px' }}
									options={[
										'Install',
										'Get',
										'Sign Up',
										'Buy',
										'Register',
										'Subscribe',
										'Shop Now',
										'Download',
										'Discover',
									]}
								/>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '35px',
								minWidth: '324px',
							}}
						>
							<div>
								<span
									style={{ color: theme.colors.text.titles, fontWeight: 500 }}
								>
									Select Templates
								</span>
							</div>
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									minWidth: '414px',
									flexDirection: 'column',
									gap: '7px',
									marginBottom: '-30px',
								}}
							>
								<div
									style={{
										display: 'flex',
										gap: '4px',
										alignItems: 'center',
										border: '1px solid #eeeeee',
										padding: '2px',
										borderRadius: '6px',
										width: '135px',
										height: '25px',
										marginBlockEnd: '7px',
									}}
								>
									<Checkbox
										disabled={checkSelectAll() ? true : false}
										style={{
											marginRight: '-10px',
											paddingInline: '2px',
										}}
										checked={selectedTemplates.length === 9}
										key={Math.random()}
										onChange={toggleSelectAllHandler}
										inputProps={{
											'aria-label': 'controlled',
										}}
										sx={{
											'&.Mui-disabled': {
												opacity: '.3',
											},
										}}
										icon={
											<CheckBoxOutlineBlankIcon
												style={{
													scale: '0.8',
													color: theme.colors.text.titles,
												}}
											/>
										}
										checkedIcon={
											<CheckBoxIcon
												style={{
													scale: '0.8',
													color: theme.colors.base.green300,
												}}
											/>
										}
									/>
									<span
										style={{
											color: theme.colors.text.titles,
											paddingInline: '4px',
											opacity: checkSelectAll() ? '.2' : '1',
										}}
									>
										Select All
									</span>
								</div>
								{Object.entries(createTemplatesDict()).map(
									(key: any, idx: number) => {
										const cat = key[0]
										const types = key[1]
										return (
											<div
												key={idx}
												style={{
													display: 'flex',
													flexDirection: 'column',
													gap: '7.5px',
													paddingBottom: '10px',
												}}
											>
												<span style={{ color: 'rgba(128,128,128,0.8' }}>
													{cat} Traffic
												</span>
												<div style={{ display: 'flex', gap: '6px' }}>
													{types.map((type: string) => {
														return (
															<div
																key={type}
																style={{
																	display: 'flex',
																	gap: '4px',
																	alignItems: 'center',
																	border: '1px solid #eeeeee',
																	padding: '2px',
																	borderRadius: '6px',
																	width: '135px',
																	height: '25px',
																}}
															>
																<Checkbox
																	style={{
																		marginRight: '-10px',
																		paddingInline: '2px',
																	}}
																	checked={isSelected(cat, type)}
																	key={Math.random()}
																	value={type}
																	onChange={() =>
																		toggleSelectedTemplateHandler(cat, type)
																	}
																	inputProps={{
																		'aria-label': 'controlled',
																	}}
																	icon={
																		<CheckBoxOutlineBlankIcon
																			style={{
																				scale: '0.8',
																				color: theme.colors.text.titles,
																			}}
																		/>
																	}
																	disabled={checkTemplatePreview(cat, type)}
																	sx={{
																		'&.Mui-disabled': {
																			opacity: '.3',
																		},
																	}}
																	checkedIcon={
																		<CheckBoxIcon
																			style={{
																				scale: '0.8',
																				color: theme.colors.base.green300,
																			}}
																		/>
																	}
																/>
																<span
																	style={{
																		color: theme.colors.text.titles,
																		paddingInline: '4px',
																		opacity: checkTemplatePreview(cat, type)
																			? '.2'
																			: '1',
																	}}
																>
																	{type}
																</span>
															</div>
														)
													})}
												</div>
											</div>
										)
									},
								)}
								<div
									style={{
										display: 'flex',
										gap: '4px',
										marginInlineStart: '-10px',
										marginBlockStart: '-6px',
									}}
								>
									{createVerticalList().map((vertical: string) => {
										if (vertical === 'All') return null
										const isSelected = selectedVertical === vertical
										const isHovered = hoveredVertical === vertical

										return (
											<SubmitButton
												key={vertical}
												onMouseEnter={() => handleMouseEnter(vertical)}
												onMouseLeave={handleMouseLeave}
												style={{
													width: '129px',
													color: isSelected
														? 'black'
														: theme.colors.text.titles,
													borderColor: isSelected
														? 'transparent'
														: theme.colors.base.grey200,
													background: isSelected
														? theme.colors.base.green100
														: isHovered
														? theme.colors.base.green20
														: theme.colors.base.grey200,
												}}
												onClick={() =>
													setSelectedVerticalHandler(selectedVertical, vertical)
												}
											>
												{vertical}
											</SubmitButton>
										)
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			)
			<div
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<SubmitButton
					onClick={previewHandler}
					disabled={
						!headline ||
						!text ||
						!cta ||
						!files.length ||
						!selectedTemplates.length
					}
					style={
						isPreview
							? {
									position: 'fixed',
									bottom: isAtBottom ? '20px' : 'auto',
									top: isAtBottom ? '1rem' : '15rem',
									right: '5rem',
									transition: 'all 0.8s ease',
									zIndex: 1000,
							  }
							: {}
					}
				>
					{isPreview ? 'Edit' : 'Preview'}
				</SubmitButton>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '50px',
					justifyContent: 'center',
				}}
			>
				{isPreview && (
					<div
						id='adTemplates'
						style={{
							display: 'flex',
							width: '1287px',
							overflowX: 'auto',
							overflowY: 'hidden',
							gap: '86px',
							justifyContent: 'center',
						}}
					>
						{selectedTemplates.length && (
							<div
								style={{
									color: theme.colors.text.titles,
									display: 'flex',
									flexDirection: 'column',
									paddingLeft: '1rem',
								}}
							>
								<div>
									{checkCategoryInSelectedTemplates('DSP & SDK') && (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<TrafficLabel label='DSP & SDK' />
											<div style={{ display: 'flex', gap: '100px' }}>
												{selectedTemplates.map(
													(template: any) =>
														template.category === 'DSP & SDK' && (
															<div
																key={Math.random()}
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	flexDirection: 'column',
																}}
															>
																<AdTemplate
																	template={template}
																	headline={headline}
																	text={text}
																	cta={cta}
																	hashtags={hashtags}
																/>
																<span
																	style={{
																		marginBlockStart: '-25px',
																		color: '#686868',
																		fontWeight: 600,
																	}}
																>
																	{template.type}
																</span>
																<UnborderedButton
																	style={{ marginBottom: '2rem' }}
																	onClick={() =>
																		template.type === 'Video'
																			? downloadVideoTemplateHandler(
																					template.id,
																					template.isHorizontal,
																			  )
																			: downloadTemplateHandler(template.id)
																	}
																	icon={<BrowserUpdatedOutlined />}
																></UnborderedButton>
															</div>
														),
												)}
											</div>
										</div>
									)}
								</div>
								<div>
									{checkCategoryInSelectedTemplates('OEM') && (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<TrafficLabel label='OEM' />
											<div style={{ display: 'flex', gap: '100px' }}>
												{selectedTemplates.map(
													(template: any) =>
														template.category === 'OEM' && (
															<div
																key={Math.random()}
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	flexDirection: 'column',
																}}
															>
																<AdTemplate
																	template={template}
																	headline={headline}
																	text={text}
																	cta={cta}
																	hashtags={hashtags}
																/>
																<span
																	style={{
																		marginBlockStart: '-25px',
																		color: '#686868',
																		fontWeight: 600,
																	}}
																>
																	{template.type}
																</span>

																<UnborderedButton
																	style={{ marginBottom: '2rem' }}
																	onClick={() =>
																		downloadTemplateHandler(template.id)
																	}
																	icon={<BrowserUpdatedOutlined />}
																></UnborderedButton>
															</div>
														),
												)}
											</div>
										</div>
									)}
								</div>
								<div>
									{checkCategoryInSelectedTemplates('Social') && (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<TrafficLabel label='Social' />
											<div style={{ display: 'flex', gap: '100px' }}>
												{selectedTemplates.map(
													(template: any) =>
														template.category === 'Social' && (
															<div
																key={Math.random()}
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	flexDirection: 'column',
																}}
															>
																<AdTemplate
																	template={template}
																	headline={headline}
																	text={text}
																	cta={cta}
																	hashtags={hashtags}
																/>
																<span
																	style={{
																		marginBlockStart: '-25px',
																		color: '#686868',
																		fontWeight: 600,
																		zIndex: 2,
																	}}
																>
																	{template.type}
																</span>
																<UnborderedButton
																	onClick={() =>
																		template.type === 'Video'
																			? downloadVideoTemplateHandler(
																					template.id,
																					template.isHorizontal,
																			  )
																			: downloadTemplateHandler(template.id)
																	}
																	icon={<BrowserUpdatedOutlined />}
																></UnborderedButton>
															</div>
														),
												)}
											</div>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}
