import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { StyledTextField, TableWrapper } from '../components/reusableComponents'
import './finance.css'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AdapterDateFns from '@date-io/date-fns'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
	decryptContacts,
	extractPaymentLimit,
	formatDateToDdMmYyyy,
	formatDateToMonth,
	getApplicableRules,
	handleFinanceStatusColor,
	sortDates,
	sortHardcodedDates,
} from '../../utils/helpers/helperFuncs'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'

import {
	Checkbox,
	MenuItem,
	TextFieldProps,
	Typography,
	useTheme,
} from '@mui/material'
import {
	GridColDef,
	GridRowModesModel,
	GridRowModes,
	GridActionsCellItem,
	GridEventListener,
	GridRowEditStopReasons,
	useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useActions, useFinanceActions } from '../../hooks/useActions'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import saveAs from 'file-saver'
import { generateStatusTable } from './SupplyStatus'
import EmailTemplateViewer from './EmailTemplateViewer'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import EmailHistoryView from './EmailHistoryView'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { DateComponent } from '../reports/aggregated/ReportsStyled'
import moment from 'moment'
import { TableComponent } from '../components/TableComponents'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { useParams } from 'react-router'
import { cs } from 'date-fns/locale'

//this is the table that will open when the user clicks on preview.
//on this page the user will see all the data regarding the publisher AND the period.
//the user will have the ability to send an status email or a final numbers email
//the rows structure is not grouped. Just filtered by publisher + period.
//When sending an email, the user will render a template of what email he is about to send (EmailTemplateViewer).
//If the user wants to see the history of the already emailed dates he will open it from the menu and render EmailHistoryView.
const SupplyBreakdown = (props: {
	setLoading: any
	setErrorMessage: any
	userEmail: string
	publisher: any
	month: any
	paramsUuid: any
	setIsEmailPreviewOpen: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
	data: any
}) => {
	const theme = useTheme()
	const rowCount = 12
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const { finance, users, settings } = useTypedSelector((state) => state)
	const [fileDownload, setFileDownload] = useState()
	const {
		sendEmailFinance,
		downloadFinanceBreakdownFile,
		uploadRollOverFinance,
	} = useFinanceActions()
	const [previewStatusEmail, setPreviewStatusEmail] = useState(false)
	const [previewFinalEmail, setPreviewFinalEmail] = useState(false)
	const [viewHistoryEmail, setViewHistoryEmail] = useState(false)
	const [lastSend, setLastSend] = useState('')
	const [financeFinalSent, setFinanceFinalSent] = useState(false)
	const { publisher, login } = useTypedSelector((state) => state)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_FINANCE),
		edit_rollover: useIsAuthorized(permissionNames.EDIT_ROLLOVER_FINANCE),
		sendEmail: useIsAuthorized(permissionNames.SEND_EMAILS),
	}
	const [financeFilterStatus, setFinanceFilterStatus] = useState(0)
	const [sortModel, setSortModel] = useState([
		{ field: 'campaign', sort: 'asc' },
	])
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const apiRef = useGridApiRef()
	const filteredRows = props.data.filter(
		(el: any) => el.publisher === props.publisher && el.month === props.month,
	)
	const [dateEdit, setDateEdit] = useState(undefined)
	const [tableRows, setTableRows] = useState<any>([])
	const [pinnedBottomRow, setPinnedBottomRow] = useState<any>([])
	const [publisherObject, setPublisherObject] = useState(
		publisher.publisher
			.filter((el: any) => el.publisher_name === props.publisher)
			.forEach(async (el: any) => {
				el.contacts = await decryptContacts(el.contacts)
			}),
	)
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const [allRows, setAllRows] = useState([])
	//here we generate the rows.
	const possibleFinanceFilter = useMemo(
		() => ['All', 'Yes', 'No', 'On Hold'],
		[],
	)
	const params = useParams()
	// useEffect(() => {
	// 	props.setSearch('')
	// }, [])
	useEffect(() => {
		// console.log('finance.finance breakdown', props.data, props.paramsUuid)
		if (props.data.length > 0) {
			const foundRow = props.data.filter((el: any) => {
				return el.uuid === props.paramsUuid
			})
			const publisher = foundRow[0].publisher

			let month = params.id!.split('-supply-')[1].replaceAll('-', '/')
			const filteredRows = props.data.filter(
				(el: any) =>
					el.publisher === publisher &&
					(el.month === month || el.roll_over_month === month) &&
					(el.status.toLowerCase() ===
						possibleFinanceFilter[financeFilterStatus].toLowerCase() ||
						'all' ===
							possibleFinanceFilter[financeFilterStatus].toLowerCase()) &&
					el.status.toLowerCase() !== 'na',
			)
			setAllRows(filteredRows)
			let filtered = filteredRows
			if (props.search.length > 0) {
				let searched = searchFor(props.search, filteredRows, columns)
				if (
					possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'all'
				) {
					filtered = searched
				} else if (
					possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'yes'
				) {
					filtered = searched.filter(
						(el: any) => el.status?.toLowerCase().trim() === 'yes',
					)
				} else if (
					possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'no'
				) {
					filtered = searched.filter(
						(el: any) => el.status?.toLowerCase().trim() === 'no',
					)
				} else if (
					possibleFinanceFilter[financeFilterStatus].toLowerCase() === 'on hold'
				) {
					filtered = searched.filter(
						(el: any) => el.status?.toLowerCase().trim() === 'on hold',
					)
				}
			}
			if (
				filtered.length > 0 &&
				filtered[0].last_send &&
				filtered[0].last_send.length > 0
			) {
				const lastElement =
					filtered[0].last_send[filtered[0].last_send.length - 1]
				setLastSend(formatDateToDdMmYyyy(lastElement))
				setFinanceFinalSent(filtered[0].final_numbers_sent)
			}
			const arr = generateRowsWithIds(filtered)
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr, props.month))
		}
	}, [financeFilterStatus, props.search, props.data])

	useEffect(() => {
		setPublisherObject(
			publisher.publisher.filter(
				(el: any) => el.publisher_name === props.publisher,
			),
		)
	}, [props.publisher])

	//this is used to download the excel of the breakdown data.
	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	const handleEditClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}
	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}

	const [pageSize, setPageSize] = useState(15)

	const handleDownloadFinance = useCallback(() => {
		downloadFinanceBreakdownFile(
			allRows,
			props.month,
			setFileDownload,
			props.setErrorMessage,
		)
	}, [allRows])
	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}
	const onHoldValues =
		tableRows.length > 0 &&
		tableRows.some(
			(el: any) =>
				el.status.toLowerCase() === 'on hold' &&
				(!el.roll_over || el.roll_over_month === props.month),
		)
	const rollOverValues =
		tableRows.length > 0 &&
		tableRows.some(
			(el: any) => el.roll_over && el.roll_over_month !== props.month,
		)
	//if there are on hold values then the user will only be able to send status.
	const sendStatusFunc = async () => {
		// console.log('rows sent', allRows)
		const worked: any = await sendEmailFinance(
			{
				rows: allRows,
				statusRows: generateStatusTable(allRows, props.month),
				publisher: publisherObject,
				email_for_logs: props.userEmail,
				month: props.month,
			},
			'status',
			props.setErrorMessage,
			setLastSend,
		)
		//this approach is just a hotfix in order to allow the user to see the history without needing to go back and rejoin.
		if (worked === true) {
			const currentTime = new Date()
			const currentTimeISO = currentTime.toISOString()
			tableRows[0].last_send.push(currentTimeISO)
		}
	}
	//if there are not on hold values then the user will be able to send the final numbers.
	const sendFinalFunc = async () => {
		const worked: any = await sendEmailFinance(
			{
				rows: allRows,
				statusRows: generateStatusTable(allRows, props.month),
				publisher: publisherObject,
				email_for_logs: props.userEmail,
				month: props.month,
			},
			'final_numbers',
			props.setErrorMessage,
			setLastSend,
			setFinanceFinalSent,
		)
		//this approach is just a hotfix in order to allow the user to see the history without needing to go back and rejoin.
		if (worked === true) {
			const currentTime = new Date()
			const currentTimeISO = currentTime.toISOString()
			tableRows[0].last_send.push(currentTimeISO)
		}
	}

	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (row.id === 9999999999) {
			return 'pinned-row'
		}
		return ''
	}

	const sendEmailOptions = useMemo(
		() => (
			<div>
				<MenuItem
					onClick={() => {
						setPreviewStatusEmail(true)
						props.setIsEmailPreviewOpen(true)
					}}
					disabled={!onHoldValues}
				>
					Send Status
				</MenuItem>
				<MenuItem
					onClick={() => {
						setPreviewFinalEmail(true)
						props.setIsEmailPreviewOpen(true)
					}}
					disabled={onHoldValues}
				>
					Send Final Numbers
				</MenuItem>

				{lastSend && (
					<MenuItem
						onClick={() => {
							setViewHistoryEmail(true)
							props.setIsEmailPreviewOpen(true)
						}}
					>
						Open Email History
					</MenuItem>
				)}
			</div>
		),
		[lastSend, onHoldValues],
	)

	const columns: GridColDef[] = [
		{
			field: 'campaign',
			headerName: 'Campaign',
			width: rowWidth * 1.4,
			disableColumnMenu: true,
			renderCell: (row) => (
				<Typography
					sx={{
						fontWeight: 600,
						fontSize: 14,
						justifyContent: row.id === 9999999999 ? 'center' : 'left',
						display: 'flex',
						width: '100%',
					}}
				>
					{row.value}
				</Typography>
			),
		},
		{
			field: 'platform',
			headerName: 'Platform',
			width: rowWidth * 0.8,
			disableColumnMenu: true,
		},
		{
			field: 'agency_account',
			headerName: 'Agency Account',
			width: rowWidth * 0.8,
			disableColumnMenu: true,
			renderCell: (row) => {
				if (row.row.agency_account === 'Other') {
					return <span>{row.row.agency_account_specification}</span>
				} else {
					return <span>{row.row.agency_account}</span>
				}
			},
		},
		{
			field: 'pid',
			headerName: 'Pid',
			width: rowWidth * 0.8,
			disableColumnMenu: true,
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
		},
		{
			field: 'cost',
			headerName: 'Cost',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'total_conversions',
			headerName: 'Total Conversions',
			width: rowWidth * 0.6,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				return (
					<span
						style={{ color: value >= 0 ? 'inherit' : 'rgba(234, 0, 61, 1)' }}
					>
						{value}
					</span>
				)
			},
		},
		{
			field: 'deducted_conversions',
			headerName: 'Deducted Conversions',
			width: rowWidth * 0.6,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				return (
					<span
						style={{ color: value >= 0 ? 'inherit' : 'rgba(234, 0, 61, 1)' }}
					>
						{value}
					</span>
				)
			},
		},
		{
			field: 'approved_conversions',
			headerName: 'Approved Conversions',
			width: rowWidth * 0.6,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.row.total_conversions - row.row.deducted_conversions
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'deduction_amount',
			headerName: 'Deduction',
			width: rowWidth * 0.6,
			type: 'number',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				if (row.id === 9999999999) {
					return (
						<span>
							{Number(value).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				if (value === '') {
					return <></>
				}
				return (
					<div
						style={{
							borderRadius: '20px',
							color: handleFinanceStatusColor(
								value === 'On Hold' ? 'on hold yellow' : value,
								theme,
							),
							width: '70px',
							height: '30px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							textTransform: 'uppercase',
							fontWeight: theme.font.weight.bolder,
							fontSize: theme.font.size.caption,
						}}
					>
						{value}
					</div>
				)
			},
		},
		{
			field: 'final_amount',
			headerName: 'Total After Deduction',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			cellClassName: 'finance-final-cell',
			disableColumnMenu: true,
			renderCell: (params) => {
				const value = params.value
				if (
					params.row.roll_over &&
					props.month !== params.row.roll_over_month
				) {
					return (
						<span style={{ textDecoration: 'line-through' }}>
							{Number(value).toFixed(2)}
						</span>
					)
				}
				return <span>{Number(value).toFixed(2)}</span>
			},
		},
		{
			field: 'roll_over',
			headerName: 'Roll-Over',
			width: rowWidth * 0.6,
			type: 'boolean',
			align: 'left',
			headerAlign: 'left',
			disableColumnMenu: true,
			editable: true,
			renderCell: (params) => {
				const row = params.row
				const value = params.value
				if (row.id === 9999999999) {
					return <></>
				}
				return (
					<Checkbox
						style={{ marginRight: '-10px' }}
						checked={value}
						key={'rollover' + value}
						inputProps={{
							'aria-label': 'controlled',
						}}
						icon={
							<CheckBoxOutlineBlankIcon
								style={{ scale: '0.7', color: theme.colors.base.green500 }}
							/>
						}
						disabled
						checkedIcon={
							<CheckBoxIcon
								style={{ scale: '0.7', color: theme.colors.base.green500 }}
							/>
						}
					/>
				)
			},
			renderEditCell: (params) => {
				const value = params.value
				return (
					<Checkbox
						style={{ marginLeft: '10px' }}
						checked={value}
						key={'rollover' + params.id}
						inputProps={{
							'aria-label': 'controlled',
						}}
						onChange={() => {
							apiRef.current.setEditCellValue({
								id: params.row.id,
								field: 'roll_over',
								value: !value,
							})
							if (!value === false) {
								apiRef.current.setEditCellValue({
									id: params.row.id,
									field: 'roll_over_month',
									value: '',
								})
							}
						}}
						icon={
							<CheckBoxOutlineBlankIcon
								style={{ scale: '0.8', color: theme.colors.base.green500 }}
							/>
						}
						checkedIcon={
							<CheckBoxIcon
								style={{ scale: '0.8', color: theme.colors.base.green500 }}
							/>
						}
					/>
				)
			},
		},
		{
			field: 'roll_over_month',
			headerName: 'Roll-Over Period',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 1.5,
			editable: true,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortDates(str1, str2)
			},
			renderEditCell: (params) => {
				const value = moment(params.value, 'DD/MM/YYYY').format('MM/DD/YYYY')
				let minDate = moment(props.month, 'DD/MM/YYYY')
				let maxDate = moment(props.month, 'DD/MM/YYYY').add(1, 'months')
				if (params.row.roll_over_month) {
					maxDate = moment(params.row.roll_over_month, 'DD/MM/YYYY').add(
						1,
						'months',
					)
				}

				return (
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DateComponent
							onChange={(e: any) => {
								const date = moment(e, 'MM/DD/YYYY')
									.date(1)
									.format('DD/MM/YYYY')
								return apiRef.current.setEditCellValue({
									id: params.row.id,
									field: 'roll_over_month',
									value: date,
								})
							}}
							onAccept={(e: any) => {
								const date = moment(e, 'MM/DD/YYYY')
									.date(1)
									.format('DD/MM/YYYY')
								return apiRef.current.setEditCellValue({
									id: params.row.id,
									field: 'roll_over_month',
									value: date,
								})
							}}
							value={params.value ? value : moment(props.month, 'DD/MM/YYYY')}
							inputFormat='MM/yyyy'
							OpenPickerButtonProps={{
								style: {
									scale: '0.9',
									marginLeft: '-20px',
									color: 'rgb(37,37,37)',
								},
							}}
							minDate={minDate}
							maxDate={maxDate}
							disabled={!params.row.roll_over}
							views={['month', 'year']}
							renderInput={(params: any) => (
								<StyledTextField
									{...params}
									focused
									// inputProps={{
									// 	...params.inputProps,
									// 	placeholder: 'mm/yyyy',
									// }}
									fullWidth
									style={{
										marginTop: '0px',
										width: '50%',
										backgroundColor: theme.colors.base.white,
										color: theme.colors.text.titles,
									}}
									// InputLabelProps={{
									// 	style: { top: '-20%' },
									// }}
								/>
							)}
						></DateComponent>
					</LocalizationProvider>
				)
			},
		},
		(permissions.edit_rollover as any) && {
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			disableColumnMenu: true,
			getActions: (row) => {
				const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
				if (row.id === 9999999999) {
					return []
				}
				if (
					(row.row.roll_over && row.row.month !== props.month) ||
					row.row.status !== 'On Hold'
				) {
					return []
				}
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',

										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<CheckIcon fontSize={'inherit'} />
								</div>
							}
							label='Save'
							sx={{
								color: 'primary.main',
							}}
							onClick={handleSaveClick(row.id)}
						/>,
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',

										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<ClearIcon fontSize={'inherit'} />
								</div>
							}
							label='Cancel'
							className='textPrimary'
							onClick={handleCancelClick(row.id)}
							color='inherit'
						/>,
					]
				}

				return [
					<GridActionsCellItem
						icon={
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									border: 'none',
									cursor: 'pointer',
									height: '28px',
									width: '28px',
									fontSize: '16px',
								}}
								className={'finance-action-button'}
							>
								<EditIcon
									style={{ color: theme.colors.text.titles, scale: '1' }}
									className={'action-button'}
								/>
							</div>
						}
						label='Edit'
						className='textPrimary'
						onClick={handleEditClick(row.id)}
						color='inherit'
					/>,
				]
			},
		},
	]
	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		const updatedRow = { ...newRow, isNew: false }
		const failed = await uploadRollOverFinance(
			newRow,
			login.user.email,
			props.setErrorMessage,
		)
		if (!failed) {
			const arr = tableRows.map((row: any) =>
				row.uuid === newRow.uuid ? updatedRow : row,
			)
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr, props.month))
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
		}
		return updatedRow
	}
	//prevent the editing with double click.
	const handleDoubleCellClick = React.useCallback((params: any, event: any) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params: any, event: any) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params: any, event: any) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])
	return (
		<>
			<TableWrapper
				theme={theme}
				rowCount={rowCount}
				setRowWidth={setRowWidth}
				style={{ marginTop: '0vh', paddingBottom: '60px', width: '90%' }}
			>
				{previewStatusEmail && (
					<EmailTemplateViewer
						publisher={publisherObject}
						statusTableRows={generateStatusTable(tableRows, props.month)}
						type={'status'}
						period={formatDateToMonth(props.month)}
						sendEmail={sendStatusFunc}
						setIsEmailPreviewOpen={props.setIsEmailPreviewOpen}
						setPreviewEmail={setPreviewStatusEmail}
						rollOverValues={rollOverValues}
					></EmailTemplateViewer>
				)}
				{previewFinalEmail && (
					<EmailTemplateViewer
						publisher={publisherObject}
						statusTableRows={generateStatusTable(tableRows, props.month)}
						type={'final'}
						period={formatDateToMonth(props.month)}
						sendEmail={sendFinalFunc}
						setIsEmailPreviewOpen={props.setIsEmailPreviewOpen}
						setPreviewEmail={setPreviewFinalEmail}
						rollOverValues={rollOverValues}
					></EmailTemplateViewer>
				)}
				{viewHistoryEmail && (
					<EmailHistoryView
						historyList={tableRows[0].last_send}
						finalWasSent={financeFinalSent}
						setViewHistoryEmail={setViewHistoryEmail}
						setIsEmailPreviewOpen={props.setIsEmailPreviewOpen}
					></EmailHistoryView>
				)}
				<TableComponent
					columns={columns}
					rows={tableRows}
					setPageSize={setPageSize}
					pageSize={pageSize}
					rowsPerPageOptions={[15, 25, 50]}
					setLoading={props.setLoading}
					rowHeight={'auto'}
					sortModel={sortModel}
					onSortModelChange={(model: any) => setSortModel(model)}
					downloadTemplate={handleDownloadFinance}
					sendEmail={permissions.sendEmail ? sendEmailOptions : undefined}
					financeLastSend={lastSend}
					financeFinalSent={financeFinalSent}
					pinnedBottomRow={pinnedBottomRow}
					getRowClassName={handleRowStyle}
					rowModesModel={rowModesModel}
					filterStatus={financeFilterStatus}
					setFilterStatus={setFinanceFilterStatus}
					mainFilterOptions={possibleFinanceFilter}
					handleRowModesModelChange={handleRowModesModelChange}
					processRowUpdate={processRowUpdate}
					handleRowEditStop={handleRowEditStop}
					handleDoubleCellClick={handleDoubleCellClick}
					handleCellKeyDown={handleCellKeyDown}
					handleCellFocusOut={handleCellFocusOut}
					apiRef={apiRef}
				></TableComponent>
			</TableWrapper>
		</>
	)
}
const calculatePinnedTotalRow = (rows: any, period: string) => {
	const pinnedRow = {
		campaign: 'TOTAL',
		platform: '',
		month: '',
		agency_account: '',
		cost: 0,
		total_conversions: 0,
		deducted_conversions: 0,
		approved_conversions: 0,
		deduction_amount: 0,
		final_amount: 0,
		status: '',
		id: 9999999999,
	}
	for (const row of rows) {
		if (row.cost) {
			pinnedRow.cost = Number(pinnedRow.cost) + Number(row.cost)
		}
		if (row.total_conversions) {
			pinnedRow.total_conversions =
				Number(pinnedRow.total_conversions) + Number(row.total_conversions)
		}
		if (row.deducted_conversions) {
			pinnedRow.deducted_conversions =
				Number(pinnedRow.deducted_conversions) +
				Number(row.deducted_conversions)
		}
		if (row.deduction_amount) {
			pinnedRow.deduction_amount =
				Number(pinnedRow.deduction_amount) + Number(row.deduction_amount)
		}
		if (!row.roll_over || row.roll_over_month === period) {
			if (row.final_amount && row.status.toLowerCase().trim() !== 'no') {
				pinnedRow.final_amount =
					Number(pinnedRow.final_amount) + Number(row.final_amount)
			}
		}
	}
	pinnedRow.approved_conversions = (
		Number(pinnedRow.total_conversions) - Number(pinnedRow.deducted_conversions)
	).toFixed(2) as any
	pinnedRow.cost = Number(pinnedRow.cost).toFixed(2) as any
	pinnedRow.deduction_amount = Number(pinnedRow.deduction_amount).toFixed(
		2,
	) as any
	pinnedRow.final_amount = Number(pinnedRow.final_amount).toFixed(2) as any
	return [pinnedRow]
}

export default SupplyBreakdown
